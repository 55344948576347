import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="postgresql"
      title="PostgreSQL Backup Service"
      description="Automate your PostgreSQL Backups and store them on any cloud provider. Support Incremental backup, flexible scheduling, Managed database (DBaaS) or self-hosted database."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automate & trust<br/>your <span className="text-primary">PostgreSQL backups</span></>)}
      subTitle={(
        <>Create automatic PostgreSQL backups without code.<br/>
          Set a schedule, a storage and let it work in peace.
        </>)}
      checkMarks={['Self-hosted database', 'DBaaS & Managed database', 'Private databases']}
      icons={[
        <Ui.Image filename="brands/icons/postgresql-ico.png" alt="PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOcean PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/aws-ico.png" alt="AWS PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/azure-ico.png" alt="Azure PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/google-cloud-ico.png" alt="Google Cloud PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/ovh-ico.png" alt="OVH PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/render-ico.png" alt="Render PostgreSQL backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/more-ico.png" alt="postgresql backup" className="w-6"/>
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your PostgreSQL Backup" urlAtrr={{ sb_source: "website", sb_term: "mysql-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-postgresql-backup-hero.png" alt="SimpleBackups PostgreSQL backup dashboard: all your PostgreSQL backups in one place"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Autoamte your backups",
                title: (<>Automated PostgreSQL Backup Scheduling.</>),
                description: (
                  <>
                    <p>
                        Automate and schedule your PostgreSQL backups as needed.
                        Set up a schedule for your PostgreSQL backups, ensuring regular, timely backups without manual intervention.
                        Tailor the backup frequency to your specific requirements, be it:
                        <Ui.FeatureChecks items={['Hourly', 'Daily', 'Weekly', 'Monthly', 'Custom Interval', 'Cron Syntax']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-backup-expert.png'
                  alt="PostgreSQL database backup"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust({subject: 'PostgreSQL databases', keyRef: 'postgresql'}),
              Content_FeatureControl({subject: 'PostgreSQL', keyRef: 'postgresql'}),
          ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">A glimpse of features we know you'll love!</Ui.Typography>

          <div className="grid md:grid-cols-3 md:gap-8 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Encrypted PostgreSQL Backups</h3>
              <p className="text-base">
                Ensure that your sensitive <span className="link-highlight">database information remains protected</span>, safeguarding it against unauthorized access during storage and transfer.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-flask-gear text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Customizable Backup Options</h3>
              <p className="text-base">Add <span className="link-highlight">custom flags</span> to tailor your backups.<br/>This flexibility ensures that your backups align with your specific operational needs.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fab fa-docker text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Backup Private and Dockerized Databases</h3>
              <p className="text-base">Support to backup of both private PostgreSQL databases and those <span className="link-highlight">running in Docker containers</span>, offering comprehensive backup solutions regardless of your deployment environment.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-bell-concierge text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Managed Postgres Database Backups</h3>
              <p className="text-base">Specifically designed to <span className="link-highlight">support managed PostgreSQL services</span>, this feature allows you to effortlessly back up databases hosted on managed services.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-list-check text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Selective or Complete Database Backups</h3>
              <p className="text-base">You can either back up all your PostgreSQL databases at once or <span className="link-highlight">select a specific database for backup</span>, catering to diverse backup strategies and requirements.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Single Command PostgreSQL Restoration</h3>
              <p className="text-base">Simplify the restoration process with the ability to <span className="link-highlight">restore your PostgreSQL backups using just a single command</span>. Quickly recover your data, minimizing downtime in case of data loss.</p>
            </div>
          </div>


          <Ui.Typography tag="h2" className="text-center mt-24">Interested in exploring the technical aspects for backups?</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups offers a user-friendly setup process, perfect for those who may not be tech experts. For the tech-savvy, we're eager to dive into the details, tailoring SimpleBackups to meet your unique requirements and maximize its potential for you.</p>
            <p className="py-5 text-lg font-bold">Hosted locally? Using a PostgreSQL DBaaS service? Not a problem!</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-cloud text-green mr-2"></i>Amazon RDS Managed Postgres</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Amazon Aurora (PostgreSQL-compatible)</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Azure Database</li>
              <li><i className="far fa-cloud text-green mr-2"></i>cPanel PostgreSQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Render Managed PostgreSQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>DigitalOcean Managed Postgres</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Google Cloud SQL PostgreSQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>OVH PostgreSQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Neon Backup</li>
              <li><i className="far fa-cloud text-green mr-2"></i>ScaleGrid Managed PostgreSQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Supabase Backup</li>
            </ul>

            <p className="mt-12 py-5 text-lg font-bold">Full-featured PostgreSQL Backup service, you’re covered:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Point in time Backup restore</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Advanced PostgreSQL flag management</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup all databases at once</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Self-hosted connections credentials</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup streaming</span>
              </li>
            </ul>
            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-16 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["postgresql"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about PostgreSQL Backups?</>}
      text="Find out more and get started with our PostgreSQL Backup guides"
      tags={['postgresql']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_postgresql_backup_service', 'how_postgresql', 'how_restore_backup', 'can_download_backup', 'use_for_client_2', 'is_secure', 'use_for_other_things']}/>


  </Layout.Layout>
)

export default ContentPage
